// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-post-js": () => import("/opt/build/repo/src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("/opt/build/repo/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("/opt/build/repo/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-tutorial-css-index-js": () => import("/opt/build/repo/src/pages/tutorial-css/index.js" /* webpackChunkName: "component---src-pages-tutorial-css-index-js" */),
  "component---src-pages-tutorial-css-lesson-10-js": () => import("/opt/build/repo/src/pages/tutorial-css/lesson-10.js" /* webpackChunkName: "component---src-pages-tutorial-css-lesson-10-js" */),
  "component---src-pages-tutorial-css-lesson-2-js": () => import("/opt/build/repo/src/pages/tutorial-css/lesson-2.js" /* webpackChunkName: "component---src-pages-tutorial-css-lesson-2-js" */),
  "component---src-pages-tutorial-css-lesson-3-js": () => import("/opt/build/repo/src/pages/tutorial-css/lesson-3.js" /* webpackChunkName: "component---src-pages-tutorial-css-lesson-3-js" */),
  "component---src-pages-tutorial-css-lesson-4-js": () => import("/opt/build/repo/src/pages/tutorial-css/lesson-4.js" /* webpackChunkName: "component---src-pages-tutorial-css-lesson-4-js" */),
  "component---src-pages-tutorial-css-lesson-5-js": () => import("/opt/build/repo/src/pages/tutorial-css/lesson-5.js" /* webpackChunkName: "component---src-pages-tutorial-css-lesson-5-js" */),
  "component---src-pages-tutorial-css-lesson-6-js": () => import("/opt/build/repo/src/pages/tutorial-css/lesson-6.js" /* webpackChunkName: "component---src-pages-tutorial-css-lesson-6-js" */),
  "component---src-pages-tutorial-css-lesson-7-js": () => import("/opt/build/repo/src/pages/tutorial-css/lesson-7.js" /* webpackChunkName: "component---src-pages-tutorial-css-lesson-7-js" */),
  "component---src-pages-tutorial-css-lesson-8-js": () => import("/opt/build/repo/src/pages/tutorial-css/lesson-8.js" /* webpackChunkName: "component---src-pages-tutorial-css-lesson-8-js" */),
  "component---src-pages-tutorial-css-lesson-9-js": () => import("/opt/build/repo/src/pages/tutorial-css/lesson-9.js" /* webpackChunkName: "component---src-pages-tutorial-css-lesson-9-js" */),
  "component---src-pages-tutorial-html-index-js": () => import("/opt/build/repo/src/pages/tutorial-html/index.js" /* webpackChunkName: "component---src-pages-tutorial-html-index-js" */),
  "component---src-pages-tutorial-html-lesson-10-js": () => import("/opt/build/repo/src/pages/tutorial-html/lesson-10.js" /* webpackChunkName: "component---src-pages-tutorial-html-lesson-10-js" */),
  "component---src-pages-tutorial-html-lesson-11-js": () => import("/opt/build/repo/src/pages/tutorial-html/lesson-11.js" /* webpackChunkName: "component---src-pages-tutorial-html-lesson-11-js" */),
  "component---src-pages-tutorial-html-lesson-2-js": () => import("/opt/build/repo/src/pages/tutorial-html/lesson-2.js" /* webpackChunkName: "component---src-pages-tutorial-html-lesson-2-js" */),
  "component---src-pages-tutorial-html-lesson-3-js": () => import("/opt/build/repo/src/pages/tutorial-html/lesson-3.js" /* webpackChunkName: "component---src-pages-tutorial-html-lesson-3-js" */),
  "component---src-pages-tutorial-html-lesson-4-js": () => import("/opt/build/repo/src/pages/tutorial-html/lesson-4.js" /* webpackChunkName: "component---src-pages-tutorial-html-lesson-4-js" */),
  "component---src-pages-tutorial-html-lesson-5-js": () => import("/opt/build/repo/src/pages/tutorial-html/lesson-5.js" /* webpackChunkName: "component---src-pages-tutorial-html-lesson-5-js" */),
  "component---src-pages-tutorial-html-lesson-6-js": () => import("/opt/build/repo/src/pages/tutorial-html/lesson-6.js" /* webpackChunkName: "component---src-pages-tutorial-html-lesson-6-js" */),
  "component---src-pages-tutorial-html-lesson-7-js": () => import("/opt/build/repo/src/pages/tutorial-html/lesson-7.js" /* webpackChunkName: "component---src-pages-tutorial-html-lesson-7-js" */),
  "component---src-pages-tutorial-html-lesson-8-js": () => import("/opt/build/repo/src/pages/tutorial-html/lesson-8.js" /* webpackChunkName: "component---src-pages-tutorial-html-lesson-8-js" */),
  "component---src-pages-tutorial-html-lesson-9-js": () => import("/opt/build/repo/src/pages/tutorial-html/lesson-9.js" /* webpackChunkName: "component---src-pages-tutorial-html-lesson-9-js" */),
  "component---src-pages-tutorial-javascript-index-js": () => import("/opt/build/repo/src/pages/tutorial-javascript/index.js" /* webpackChunkName: "component---src-pages-tutorial-javascript-index-js" */),
  "component---src-pages-tutorial-javascript-lesson-10-js": () => import("/opt/build/repo/src/pages/tutorial-javascript/lesson-10.js" /* webpackChunkName: "component---src-pages-tutorial-javascript-lesson-10-js" */),
  "component---src-pages-tutorial-javascript-lesson-11-js": () => import("/opt/build/repo/src/pages/tutorial-javascript/lesson-11.js" /* webpackChunkName: "component---src-pages-tutorial-javascript-lesson-11-js" */),
  "component---src-pages-tutorial-javascript-lesson-12-js": () => import("/opt/build/repo/src/pages/tutorial-javascript/lesson-12.js" /* webpackChunkName: "component---src-pages-tutorial-javascript-lesson-12-js" */),
  "component---src-pages-tutorial-javascript-lesson-13-js": () => import("/opt/build/repo/src/pages/tutorial-javascript/lesson-13.js" /* webpackChunkName: "component---src-pages-tutorial-javascript-lesson-13-js" */),
  "component---src-pages-tutorial-javascript-lesson-2-js": () => import("/opt/build/repo/src/pages/tutorial-javascript/lesson-2.js" /* webpackChunkName: "component---src-pages-tutorial-javascript-lesson-2-js" */),
  "component---src-pages-tutorial-javascript-lesson-3-js": () => import("/opt/build/repo/src/pages/tutorial-javascript/lesson-3.js" /* webpackChunkName: "component---src-pages-tutorial-javascript-lesson-3-js" */),
  "component---src-pages-tutorial-javascript-lesson-4-js": () => import("/opt/build/repo/src/pages/tutorial-javascript/lesson-4.js" /* webpackChunkName: "component---src-pages-tutorial-javascript-lesson-4-js" */),
  "component---src-pages-tutorial-javascript-lesson-5-js": () => import("/opt/build/repo/src/pages/tutorial-javascript/lesson-5.js" /* webpackChunkName: "component---src-pages-tutorial-javascript-lesson-5-js" */),
  "component---src-pages-tutorial-javascript-lesson-6-js": () => import("/opt/build/repo/src/pages/tutorial-javascript/lesson-6.js" /* webpackChunkName: "component---src-pages-tutorial-javascript-lesson-6-js" */),
  "component---src-pages-tutorial-javascript-lesson-7-js": () => import("/opt/build/repo/src/pages/tutorial-javascript/lesson-7.js" /* webpackChunkName: "component---src-pages-tutorial-javascript-lesson-7-js" */),
  "component---src-pages-tutorial-javascript-lesson-8-js": () => import("/opt/build/repo/src/pages/tutorial-javascript/lesson-8.js" /* webpackChunkName: "component---src-pages-tutorial-javascript-lesson-8-js" */),
  "component---src-pages-tutorial-javascript-lesson-9-js": () => import("/opt/build/repo/src/pages/tutorial-javascript/lesson-9.js" /* webpackChunkName: "component---src-pages-tutorial-javascript-lesson-9-js" */),
  "component---src-pages-tutorial-jquery-index-js": () => import("/opt/build/repo/src/pages/tutorial-jquery/index.js" /* webpackChunkName: "component---src-pages-tutorial-jquery-index-js" */),
  "component---src-pages-tutorial-jquery-lesson-10-js": () => import("/opt/build/repo/src/pages/tutorial-jquery/lesson-10.js" /* webpackChunkName: "component---src-pages-tutorial-jquery-lesson-10-js" */),
  "component---src-pages-tutorial-jquery-lesson-11-js": () => import("/opt/build/repo/src/pages/tutorial-jquery/lesson-11.js" /* webpackChunkName: "component---src-pages-tutorial-jquery-lesson-11-js" */),
  "component---src-pages-tutorial-jquery-lesson-12-js": () => import("/opt/build/repo/src/pages/tutorial-jquery/lesson-12.js" /* webpackChunkName: "component---src-pages-tutorial-jquery-lesson-12-js" */),
  "component---src-pages-tutorial-jquery-lesson-2-js": () => import("/opt/build/repo/src/pages/tutorial-jquery/lesson-2.js" /* webpackChunkName: "component---src-pages-tutorial-jquery-lesson-2-js" */),
  "component---src-pages-tutorial-jquery-lesson-3-js": () => import("/opt/build/repo/src/pages/tutorial-jquery/lesson-3.js" /* webpackChunkName: "component---src-pages-tutorial-jquery-lesson-3-js" */),
  "component---src-pages-tutorial-jquery-lesson-4-js": () => import("/opt/build/repo/src/pages/tutorial-jquery/lesson-4.js" /* webpackChunkName: "component---src-pages-tutorial-jquery-lesson-4-js" */),
  "component---src-pages-tutorial-jquery-lesson-5-js": () => import("/opt/build/repo/src/pages/tutorial-jquery/lesson-5.js" /* webpackChunkName: "component---src-pages-tutorial-jquery-lesson-5-js" */),
  "component---src-pages-tutorial-jquery-lesson-6-js": () => import("/opt/build/repo/src/pages/tutorial-jquery/lesson-6.js" /* webpackChunkName: "component---src-pages-tutorial-jquery-lesson-6-js" */),
  "component---src-pages-tutorial-jquery-lesson-7-js": () => import("/opt/build/repo/src/pages/tutorial-jquery/lesson-7.js" /* webpackChunkName: "component---src-pages-tutorial-jquery-lesson-7-js" */),
  "component---src-pages-tutorial-jquery-lesson-8-js": () => import("/opt/build/repo/src/pages/tutorial-jquery/lesson-8.js" /* webpackChunkName: "component---src-pages-tutorial-jquery-lesson-8-js" */),
  "component---src-pages-tutorial-jquery-lesson-9-js": () => import("/opt/build/repo/src/pages/tutorial-jquery/lesson-9.js" /* webpackChunkName: "component---src-pages-tutorial-jquery-lesson-9-js" */),
  "component---src-pages-tutorials-js": () => import("/opt/build/repo/src/pages/tutorials.js" /* webpackChunkName: "component---src-pages-tutorials-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

